const aboutUs = {
    title: "Rosendale Farms",
    paragraphs: [
        "Rosendale Farms Ltd, is a fully licensed grain elevator in Bloomingdale ON. We receive corn, soybeans, non-gmo soybeans, soft red, hard red winter wheat, and spring wheat.",
        "Our location has a receiving capacity of 30,000 bu/hr and a drying capacity of 120mt/hr with an on-site storage capacity of 1,500,000 bushels. We are proud to work with Frey Farms, which is a satellite receiving location East of Elmira—receiving corn, soybeans, soft red, and hard red winter wheat, as well as spring wheat.",
        "The grain industry is built on strong relationships.Rosendale Farms provides competitive bids, quick payment, and knowledgeable grain marketing advice.  Give us a call regarding grain marketing plans, trucking at harvest as well as direct to end - user bids."
    ],
    emailList: [
        "bryan@rosendalefarms.com",
        "brad@rosendalefarms.com",
        "craig@rosendalefarms.com"
    ],
    landing: "We are excited to announce the release of our new site! You can easily view Rosendale Farms' cash bids, futures, weather and more."
}

export default aboutUs;