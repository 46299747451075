import React from "react";
import rockyPond from "..";
import Press from '../../../assets/images/logos/rockyPond/dupps_press.jpeg';
import Soybeans from '../../../assets/images/logos/rockyPond/home_img.jpeg'
import Piglets from '../../../assets/images/logos/rockyPond/piglets.jpg'
import SoyField from '../../../assets/images/logos/rockyPond/soybean-field.jpg'

function Products() {
	return (
		<div
			className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
			style={{ backgroundColor: rockyPond.styles.backgroundColor }}
		>
			<ProductSection
				img={Press}
				title="GoldPass - Dairy Nutrition"
				description="NPI GoldPass is an expeller processed soy protein that provides a consistent source of high quality protein and amino acids for high producing dairy cows, as well as a rich source of added energy, with a fat content of 5%. The typical protein content is 42% as fed and the 7% fiber comes from highly digestible soy hulls. The higher dry matter content (about 7% higher than regular soy) of the NPI GoldPass meal provides additional value in every ton and helps maintain improved flow-ability. The meal is manufactured under strict quality control guidelines to produce a bypass soybean meal that has consistent particle size and color.  The bypass protein in the NPI GoldPass is a high quality bypass protein that compliments the amino acid needs of high producing dairy cows. The “in situ” rumen bypass measurements have been measured and tested at the Cumberland Valley Analytical Services to show a typical high bypass protein level of 60%. On-going quality assurance analysis is being done regularly to assure quality.  NPI GoldPass will generally replace the regular soybean meal and much of the other protein and bypass protein products in rations. Normal feeding rates for lactating dairy cows will range from 4 to 8 pounds per head per day, depending on the ration forages and production goals. We suggest that you consult with your Nutritionist to determine the optimum rate."
			/>
			<ProductSection
				img={Piglets}
				title="GoldPro – Hog & Poultry Nutrition"
				description="Our GoldPro meal is an expeller meal which not only improves digestibility, but leaves the meal with a superior smell and taste, improving feed intake in livestock.  Our guaranteed analysis is 42% Protein and 5.5% fat."
			/>
			<ProductSection
				img={SoyField}
				title="Soy Oil"
				description="Our water degummed soybean oil is low in phosphorus and sulphur and is a great way to improve the energy density of a feed ration, especially for broiler rations, or in lactating sows and piglet rations.  Available picked up at our plant or delivered we can help you achieve the productivity you desire on your farm.  Give us a call or send us an email for more detailed information or for price inquiries."
			/>
			<ProductSection
				img={Soybeans}
				title="Soybeans"
				description="Natural Proteins Inc. is committed to helping farmers grow the best soybeans in Manitoba and providing a local, competitive market for the beans.  If you grow soybeans, we would love to buy them.  Please contact us for marketing options or to place your targets.  We also buy soybeans picked up in your yard, so let us know what you have and we would be glad to offer you a bid.  You can sign up for our regular emails by contacting Darrell Hiebert."
				bulletPoints={[
					"Contact Darrell Hiebert at (204) 381-6370",
				]}
			/>
			<p className="text-xl pt-4 text-white">For more detailed information or for price inquiries please contact Art Veenendaal at <a className="hover:text-white hover:text-1xl" href="tel:204-396-7378">(204) 396-7378</a>.</p>
		</div>
	);
}

function ProductSection(props) {
	return (
		<div className="flex flex-col-reverse tablet:flex-row py-4">
			{typeof props.img !== "undefined" && (
				<img
					className="rounded-lg shadow-md h-80  tablet:w-80 object-cover"
					src={props.img}
					alt={props.title}
				/>
			)}
			<div className="pb-10 tablet:pb-0 tablet:pl-10">
				<h2
					className="text-4xl font-bold"
					style={{ color: rockyPond.styles.primaryColor }}
				>
					{props.title}
				</h2>

				<p className="max-w-2xl text-xl pt-4" style={{ color: `${rockyPond?.styles?.paragraphFontColor || '#000'}` }}>{props.description}</p>
				{typeof props.bulletPoints !== "undefined" && (
					<ul className="pl-10 pt-2">
						{props.bulletPoints.map((points) => (
							<li key={points} className="list-disc text-gray-600" style={{ color: `${rockyPond?.styles?.paragraphFontColor || '#000'}` }}>
								{points}
							</li>
						))}
					</ul>
				)}
			</div>

			{typeof props.trailingImage !== "undefined" && (
				<img
					className="pb-10 tablet:pb-0 tablet:pl-10"
					src={props.trailingImage}
					alt="test"
				></img>
			)}
		</div>
	);
}
export default Products;
