const aboutUs = {
    title: "Addis Grain",
    paragraphs: [
        "Addis Grain was created in the early months of 2019.  Addis Grain is a privately owned family elevator formally known as Hewitt Creek Farms in Innisfil, Ontario. Over the past few years the opportunity presented itself for the Eisses family to build a new 500,000 bushel  state of the art grain elevator in Oro-Medonte, Ontario.  The Eisses family has been farming dairy and grain crops since the early 60’s when they first migrated from Holland.  We strive on honest reliable service that adds value to your farming operation.  Outside of farming we believe in the importance of being directly involved with the communities we both work and live in.   Below is a recent article that was posted in the FARMVIEW September 2019 edition:",
        "A new grain elevator is open for business for this soybean and corn harvest in Simcoe County.  Addis Grain Ltd. Is taking over the Hewitt Creek Farms grain elevator business that was previously located in Innisfil.   The Eisses family has farmed in Oro-Medonte for over 15 years and has expanded their business to help provide grain marketing, custom drying and storage to local producers.   The state-of-the-art grain handling facility is located on the 3rd line of Oro-Medonte, just south of Hwy 11.  The location is conveniently located next to the 3rd line overpass to accommodate grain coming from any direction.  A two-lane dump pit and receiving elevator has the capacity to unload 15,000 bu/hour to service area producers, getting trucks, and wagons back to the field quicker during the busy harvest season.  Addis Grain has trucks available to assist moving grain from the farm to the elevator as needed.",
        "Looking forward to 2020, Addis Grain Ltd will receive wheat as well.  Forward contracting is always available with competitive pricing on corn, soybeans and wheat.  Addis Grain Ltd. Is independent and not affiliated with any other commodity buyers, allowing for larger market access when marketing grain.",
        "Kevin Eisses has managed Hewitt Creek Farms as a grain elevator for almost 30 years.When the opportunity arose to build a new facility in Oro-Medonte it didn’t take him long to decide to jump at it.The area in North Simcoe was lacking such a facility and as more grain is being produced there, farmers have been challenged to get their crops off during tight harvest windows.The name Addis Grain originated from a Food Study Tour sponsored by the Canadian Foodgrains Bank (CFGB) that Kevin participated in during the winter of 2016 that went to Ethiopia. It was a very gratifying trip to visit Ethiopian farmers and confirm the support that CFGB offers to the. The CFGB is supported largely by growing projects such as the “Loaves and Fishes” growing project that the Eisses family is apart of in South Simcoe.The name “Addis” is part of the capital city in Ethiopia named Addis Ababa.Addis means “new” in the Amharic language which is one of Ethiopia’s national languages. Addis Grain will continue to support CFGB in its goal of “a world without hunger”.",
        "Kevin Eisses, President"
    ],
    emailList: [
        "kevin.addisgrain@gmail.com",
        "graham.addisgrain@gmail.com",
    ],
    landing: ["We are excited to announce the release of our new site! You can easily view Addis Grains' cash bids, futures, weather and more."],
    showHomePageContact: true,
    joinUs: true
}

export default aboutUs;