const aboutUs = {
    title: "Our Companies",
    paragraphs: [
        "Our Natural Process",
        "Natural Proteins Inc. uses a completely mechanical process that does not use any solvents. Soybeans are subjected to a high temperature short time thermal process in an extruder. This treatment deactivates undesirable enzyme systems, releases the oil for easy separation, and improves the digestibility of protein.",
        "The thermal process is followed by mechanical pressing in a screw press to separate the oil. The extracted meal is high in protein and has a superior flavor. Because we do not separate the soybean into its components, the natural balance of the nutrients is maintained through the process. Our products are therefore truly natural.",
        "Due to our mechanical extraction process, our products retain the antioxidants present in the soybean and are consequently naturally stable. This process also creates a superior flavor compared to conventional counterparts.",
        "Natural Proteins Inc. is a Manitoba company providing a Made in Manitoba Solution to the feed industry."
    ],
    landing: [
        "Natural Proteins Inc. is a soybean processing facility located in Manitoba on the eastern side of the Red River Valley.  We strive to be the first choice for farmers selling their soybeans as well as for local livestock farmers and feed mills who are buying soybean meal and soy oil for their rations.  Our mechanically processed soybean meal is a great source of high quality protein and energy.",
        "Rocky Pond Commodities was established in 2004 and acquired by Natural Proteins Inc. in 2015. Rocky Pond Commodities has allowed us to expand our professional services into the grains market. RPC works closely with producers to provide marketing solutions and  supply local mills as well as end users with feed grains.  RPC trades Wheat, Barley, Corn and Oats along with additional feed and grains by products.  Feel free to reach out to one of our traders today!"
    ],
    emailList: [
        "info@naturalproteins.ca",
    ],
    joinUs: false,
    showHomePageContact: false,
    showHomePageMap: true,
}

export default aboutUs;