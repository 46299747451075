const aboutUs = {
    title: "RKM Grain",
    paragraphs: [
        "Family owned business incorporated in 2004, aiming to serve the commodity cash crop farmers in our local area. Fully licenced grain dealer and storage facility, local delivery point for grain at harvest time."
    ],
    emailList: [
        "rkmgrain@gmail.com",
    ],
    joinUs: true
}

export default aboutUs;