const aboutUs = {
	title: "Clearview Grain",
	paragraphs: [
		"ClearView Grain is a family owned grain business. It is located in the township of Middlesex Centre approximately 12 km north of London.  John and Greg Walls constructed the initial farm bins and stack dryer in 1997.  They began custom drying and handling for other growers in 1999.  They incorporated as ClearView Grain Inc. in 2001.  At this point in time ClearView Grain Inc.  became a licensed commercial grain dealer.",
		" The commercial elevator has expanded throughout the last 15 years to 1 million bushels of storage.  Commodities handled include corn, wheat, and soybeans.  Food grade soybeans are also received.  Currently we have receiving capabilities of 13000bu/hour.  There are 2 tower dryers at the facility for drying capacity of 3500 bu/hour at 10 point removal.",
		"The family run business also includes 1 full time employee and 3 part time employees.  We continue to grow and adapt to the ever changing landscape in agriculture.  Our goal is to provide essential customer service and competitive pricing.  ClearView Grain Inc. looks forward to servicing producers today and into the future.",
	],
	emailList: [
		"clearviewgrain@isp.ca"
	],
	joinUs: true
};

export default aboutUs;
