const aboutUs = {
  title: "Talbot Elevators",
  paragraphs: [
    "Talbot Elevators Ltd. is a Family owned and operated Grain Elevator, Seed Retailer and Feed Mill located in Aylmer, ON, with a second seasonal elevator location in Straffordville, ON. Owned by the Killough family, Brett and Holly along with their five kids since 2005.",
    "Brett and Holly are cash croppers, and backyard livestock raisers themselves. They understand the farmer side of grain marketing and what quality feed can do. Brett studied Agriculture through the University of Guelph and continues his grain marketing education through training courses and conferences to stay current with modern marketing. His family has been farming his whole life and continue to do so. Brett's experience with poultry, dairy and crops can be seen clearly and appreciated when looking at the business that he and Holly have built.",
    "While Brett focuses on the grain marketing side of things and trucking feed when he's needed, Holly organizes feed sales and works directly with nutritionists to ensure a top quality product. She has taken and continues to take livestock nutrition courses and attend conferences to expand her knowledge  and keep up with new research findings. Holly's dedication to making the best feed possible is apparent to anyone who talks to her about the feed programs she offers.",
    "Meet the family, they're excited to work with you!",
  ],
  joinUs: true
};

export default aboutUs;
