import React from "react";
import location from "../location";
import styles from "../styles";
import NPILogo from '../../../assets/images/logos/rockyPond/npi-logo.png'
import RPCLogo from '../../../assets/images/logos/rockyPond/rpc-logo.png'
import Placeholder from '../../../assets/images/icons/placeholder-image.png'
import Art from '../../../assets/images/logos/rockyPond/Art.png'
import Darrell from '../../../assets/images/logos/rockyPond/Darrell.png'
import Kathy from '../../../assets/images/logos/rockyPond/Kathy.png'
import Keith from '../../../assets/images/logos/rockyPond/Keith.png'
import Rene from '../../../assets/images/logos/rockyPond/Rene.png'
import Scott from '../../../assets/images/logos/rockyPond/Scott.png'
import { CustomMap } from "../../../components/map/Map";
import Config from "../../config";

const renderImage = (name) => {
	switch (name) {
		case 'Art':
			return Art;
		case 'Darrell':
			return Darrell;
		case 'Kathy':
			return Kathy;
		case 'Keith':
			return Keith;
		case 'Rene':
			return Rene;
		case 'Scott':
			return Scott;
		default:
			return Placeholder;
	}
}
function ContactUs() {
	return (
		<section id="team" className="flex w-full flex-col mx-10">
			<div className="flex items-center mt-[200px]">
				<img className="h-[100px] rounded-sm" src={RPCLogo} alt="profile" />
				<h1
					className="text-6xl font-bold ml-10"
					style={{ color: '#FFF' }}
				>
					Rocky Pond Team
				</h1>
			</div>
			<br />
			<div className="grid grid-cols-1 tablet:grid-cols-4 gap-0 tablet:gap-4">
				{location.teamProfileRPC.map((member) => (
					<TeamProfile
						key={member.name}
						name={member.name}
						position={member.position}
						phone={member.phone}
						email={member.email}
						img={member.img}
					></TeamProfile>
				))}
			</div>
			<div className="flex items-center">
				<img className="h-[100px] rounded-sm" src={NPILogo} alt="profile" />
				<h1
					className="text-6xl font-bold ml-10"
					style={{ color: '#FFF' }}
				>
					Natural Proteins Team
				</h1>
			</div>
			<br />
			<div className="grid grid-cols-1 tablet:grid-cols-4 gap-0 tablet:gap-4">
				{location.teamProfileNPI.map((member) => (
					<TeamProfile
						key={member.name}
						name={member.name}
						position={member.position}
						phone={member.phone}
						email={member.email}
						img={member.img}
					></TeamProfile>
				))}
			</div>
			<br />
			<div className="flex flex-col items-center mb-[50px] font-semibold" style={{ color: 'white' }}>
				<p>Main Address: Box 330, Blumenort, MB R0A 0C0</p>
				<a style={{ color: 'white' }} href="tel:204-355-5040">(204) 355-5040</a>
				<a style={{ color: 'white' }} href="fax:204-355-5041">(204) 355-5041</a>
			</div>
			<div className="flex rounded-3xl overflow-clip tablet:w-3/4 mx-auto mb-10">
				<CustomMap coordinate={Config?.location?.coordinate} />
			</div>
		</section>
	);
}

function TeamProfile(props) {
	return (
		<div className="flex flex-col py-10 px-10  items-center justify-center">
			<img
				className="h-40 w-40 rounded-full"
				src={renderImage(props?.img) || Placeholder}
				alt="profile"
			></img>
			<br></br>
			<p className="text-center font-bold" style={{ color: `${styles?.paragraphFontColor || '#000'}` }}>{props.name}</p>
			<p className="text-center text-sm" style={{ color: `${styles?.paragraphFontColor || '#000'}` }}>{props.position}</p>
			<a className="text-center text-sm text-white" href={`tel:${props?.phone}`}>{props.phone}</a>
			<a className="text-center text-sm text-white" href={`mailto:${props?.email}`}>{props.email}</a>
		</div>
	);
}

export default ContactUs