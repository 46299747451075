const aboutUs = {
	title: "St. Lawrence Grains",
	paragraphs: [
		"St. Lawrence Grains & Farm Supply Ltd. is a full-service license grain elevator and dealer with locations in Stouffville and Havelock ON. SLG is receiving both wet and dry Soybeans and Soft Red Winter Wheat at our Stouffville location and wet and dry Corn at both Stouffville and Havelock locations.",
		"SLG is in partnership with Kawartha Ethanol with our merchandising agreement going back to 2016. We work together to serve our customers by offering shipping from in house and off farm year-round. For direct delivery and off farm contracts please contact our head office.",
		"As a licensed seed treating and farm supply facility, we carry a wide array of crop care products and custom fertilizers for all of your agronomy needs.",
		"Our Stouffville facility holds two receiving pits for Corn, Soybeans and SRW Wheat. At our Stouffville location we receive 10,000 BU/HR with dryer capability of 80 MT/HR. Our Havelock location has on site capacity of 570,000 bushels.",
		"We are a proud licensed seed treating facility offering crop scouting, crop planning, soil testing, spreader rental and delivery services and custom application. At St. Lawrence Grains we accept wet corn and offer grain drying services for our farmers. Farmers can conveniently bring their wet corn to our facility and take dry corn for their livestock feed needs.",
	],
	landing:
		["We are excited to announce the release of our new farmer app! You can easily view live bids, and coming soon: view your open contracts, open storage and create target offers right from your smartphone"],
	emailList: [
		"tracie@SLGRAINSFS.CA",
		"hagit@SLGRAINSFS.CA"
	],
	joinUs: true
};

export default aboutUs;
