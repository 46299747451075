import { Route } from "react-router-dom";
import appDetails from "./appDetails";
import Products from "./custom-pages/products";
import ContactUs from "./custom-pages/contactUs";
import MarketplaceSignUp from "../../pages/signUp/MarketplaceSignUp";

const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Futures",
			link: "/futures",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: "Products",
			link: "/products",
			show: true,
		},
		{
			title: "Contact Us",
			link: "/contact-us",
			show: true,
		},
		{
			title: "Sign Up",
			link: "/marketplace-sign-up",
			show: true,
		},
		{
			title: "Login",
			link: appDetails?.webLink,
			show: true
		}
	],
	customNavRoute: [
		<Route key={"products"} path="/products" component={Products}></Route>,
		<Route key={"contact us"} path="/contact-us" component={ContactUs}></Route>,
		<Route key={"marketplace-sign-up"} path="/marketplace-sign-up" component={MarketplaceSignUp}></Route>,
	],
};

export default navItems;
